<template>
  <b-container class="platform-event-detail">
    <b-row>
      <b-col cols="12">
        <b-img :src="event.image" alt="Event Image" fluid class="w-100 mb-4"></b-img>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <h1>{{ event.subject }}</h1>
        <p>{{ event.description }}</p>
        <b-list-group>
          <b-list-group-item><strong>Start Date:</strong> {{ event.start_date }} {{ event.start_time_short }}</b-list-group-item>
          <b-list-group-item><strong>End Date:</strong> {{ event.end_date }} {{ event.end_time_short }}</b-list-group-item>
          <b-list-group-item><strong>Region:</strong> {{ event.region_name }}, {{ event.region_country_name }}</b-list-group-item>
          <b-list-group-item><strong>Address:</strong> {{ event.address }}</b-list-group-item>
          <b-list-group-item><strong>Price:</strong> {{ event.price }} {{ event.currency }}</b-list-group-item>
          <b-list-group-item><strong>Contact:</strong> {{ event.email }} | {{ event.phone_number }}</b-list-group-item>
          <b-list-group-item><strong>Facebook:</strong> <a :href="event.facebook_url" target="_blank">{{ event.facebook_url }}</a></b-list-group-item>
          <b-list-group-item><strong>Instagram:</strong> <a :href="event.instagram_url" target="_blank">{{ event.instagram_url }}</a></b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from '@/api/api'
import { mapMeta } from 'vue-meta'

export default {
  name: 'PlatformEventDetail',
  data() {
    return {
      event: {}
    }
  },
  metaInfo() {
    return {
      title: `${process.env.VUE_APP_TITLE} | ${this.event.subject}`,
      meta: [
        { name: 'description', content: this.event.description },
        { property: 'og:title', content: this.event.subject },
        { property: 'og:description', content: this.event.description },
        { property: 'og:image', content: this.event.image },
        { property: 'og:url', content: window.location.href }
      ]
    }
  },
  created() {
    this.fetchEvent()
  },
  methods: {
    async fetchEvent() {
      const slug = this.$route.params.slug
      const response = await api.platformEvent({ slug })
      this.event = response.data.results[0] // Assuming the API returns an array of events
    }
  }
}
</script>

<style scoped>
.platform-event-detail {
  padding: 20px;
}
</style>
